import { PropsWithChildren } from 'react';
import Layout from '.';

export default function DefaultLayout({
  children,
}: PropsWithChildren<Record<string, unknown>>) {
  return (
    <Layout.Root>
      <Layout.Header />
      <Layout.Main>{children}</Layout.Main>
      <Layout.Footer />
      <Layout.BottomNavigation />
    </Layout.Root>
  );
}
